import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/headers/page-header"
import ContentBox from "../components/content-box"
import { OutboundLink } from "gatsby-plugin-google-analytics"

class PagePrivacyPolicy extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Privacy Policy"/>

        <PageHeader>Privacy Policy</PageHeader>
        <ContentBox>
          <p>Paranoid American takes privacy seriously. To better protect your privacy we provide this privacy policy
            notice explaining the way your personal information is collected and used.</p>


          <h5>Collection of Routine Information</h5>
          <p>This website tracks basic information about their users. This information includes, but is not limited to,
            IP addresses, browser details, timestamps and referring pages. None of this information can personally
            identify specific visitors to this website. The information is tracked for routine administration and
            maintenance purposes.</p>


          <h5>Cookies</h5>
          <p>Where necessary, this website uses cookies to store information about a visitor’s preferences and history
            in
            order to better serve the visitor and/or present the visitor with customized content.</p>


          <h5>Advertisement and Other Third Parties</h5>
          <p>Advertising partners and other third parties may use cookies, scripts and/or web beacons to track visitors
            activities on this website in order to display advertisements and other useful information. Such tracking is
            done directly by the third parties through their own servers and is subject to their own privacy policies.
            This website has no access or control over these cookies, scripts and/or web beacons that may be used by
            third
            parties. Learn how to <OutboundLink target={'_blank'} rel={'nofollow'} href={"http://www.google.com/privacy_ads.html"}>opt out of Google’s
              cookie
              usage</OutboundLink>.</p>


          <h5>Links to Third Party Websites</h5>
          <p>Links on this website are for your use and reference. Paranoid American is not responsible for the privacy
            policies on these websites. You should be aware that the privacy policies of these websites may differ from
            our own.</p>


          <h5>Security</h5>
          <p>The security of your personal information is important, but remember that no method of transmission over
            the
            Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable
            means to protect your personal information, we cannot guarantee its absolute security.</p>


          <h5>Changes To This Privacy Policy</h5>
          <p>This Privacy Policy is effective as of 1/1/2020 and will remain in effect except with respect to any
            changes
            in its provisions in the future, which will be in effect immediately after being posted on this page.</p>

          <p>Paranoid American reserves the right to update or change this Privacy Policy at any time and you should
            check
            this Privacy Policy periodically. If any material changes are made to this Privacy Policy, they will be
            updated here.</p>


          <h5>Contact Information</h5>
          <p>For any questions or concerns regarding the privacy policy, please send an email to <a
            href={"mailto:contact@paranoidamerican.com"}>contact@paranoidamerican.com</a>.</p>
        </ContentBox>

      </Layout>
    )
  }
}

export default PagePrivacyPolicy